import React from 'react'
import './Blog.css';
import BlogSummary from '../BlogSummary';
import chokeberry from './Blogs Posts/postImages/chokeberry.jpg'
import sprinkler from './Blogs Posts/postImages/sprinkler.jpg'
import apple from './Blogs Posts/postImages/apple.jpg'
import appletree from './Blogs Posts/postImages/appletree.jpg'
import gardening from './Blogs Posts/postImages/gardening.jpg'
import fruitpickingvolunteers from './Blogs Posts/postImages/fruitpickingvolunteers.png'
import GoToTop from '../GoToTop';




function Blog() {
  return (
    <>
    <GoToTop />
        <center><h1 className='blogPostsTitle'>Blog Posts</h1></center>
        <div className='postContainer'>
            <BlogSummary linkPath = '/Blogs/FruitsWeLove' image={apple} title='The Bounty of Backyard Harvests: Fruits We Love and How You Can Contribute' content="At Fruit Share, we're passionate about collecting surplus fruits from generous homeowners to share with those in need. The fruits we gather are diverse, reflecting the rich variety of backyard gardens. Some of our favorites include apples, oranges, lemons, pears, and peaches. Each fruit not only adds delicious flavors to meals but also provides essential nutrients to those who might not otherwise have access to fresh produce."/>
            <BlogSummary linkPath = '/Blogs/EffectivePlantHydration' image={sprinkler} title="Efficient Plant Hydration: The Science and Engineering of Drip Irrigation" content="In the quest for a thriving garden, efficient and consistent hydration is crucial. Traditional watering methods like sprinklers or hoses can be wasteful and uneven. Fortunately, a science and engineering-backed solution, known as drip irrigation, offers a more efficient way to hydrate your plants with precision and minimal waste."/>
            <BlogSummary linkPath = '/Blogs/RevitalizeYourGarden' image={gardening} title="Revitalize Your Garden and Reignite Your Gardening Dreams" content="It's easy to feel overwhelmed when your garden isn't thriving as you hoped. But don't give up on your gardening dreams just yet! With a few thoughtful steps, you can transform your space into a beautiful and productive oasis. Here's how to renovate your garden and rekindle your passion for gardening."/>
            
            <BlogSummary linkPath = '/Blogs/HelpingOurVolunteers'image={appletree} title="Maximizing the Productivity of Our Fruit-Picking Volunteers: A Homeowner’s Guide" content="Hosting our dedicated volunteers to pick fruits from your trees is a fantastic way to contribute to our cause. To ensure the process is as efficient and productive as possible, here are some detailed tips to help you prepare and collaborate effectively."/>
            <BlogSummary linkPath = '/Blogs/Superfruits' image={chokeberry} title="Discover the Hidden Power of These 3 Superfruits" content="While many fruits are celebrated for their health benefits, some lesser-known varieties pack an impressive nutritional punch. These 'superfruits' are rich in vitamins, antioxidants, and other nutrients that can boost your health in surprising ways. Here are three superfruits that deserve more recognition and a place in your diet."/>
            <BlogSummary linkPath = '/Blogs/GetLeadership' image={fruitpickingvolunteers} title="Take Your Contributions to the Next Level: Join Our Leadership Team" content="If you’re passionate about our mission and want to make a more significant impact, consider stepping into a leadership role within our organization. This is a unique opportunity to contribute at a higher level and help drive our initiatives forward. Here’s how you can take your involvement to the next level."/>
        </div>
    </>
  )
}

export default Blog