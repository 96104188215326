import "./Question.css";
import React from 'react';

const Question = ({ask, answer}) => {
  return (
    <div className = 'question'>
    <h3>{ask}</h3>
          <h4>
            {answer}
        </h4>
    </div>
  );
};

export default Question;