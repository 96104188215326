import React, { useRef, useEffect } from 'react';
import './TransitionText.css';

const TransitionText = ({ text, Tag = 'h1' }) => {
  const elementRef = useRef(null);
  const letterRefs = useRef([]);

  useEffect(() => {
    const handleScroll = () => {
      if (elementRef.current) {
        const rect = elementRef.current.getBoundingClientRect();
        const isVisible = (
          rect.top >= 0 &&
          rect.left >= 0 &&
          rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
          rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
        if (isVisible) {
          letterRefs.current.forEach((letter, index) => {
            if (letter) {
              letter.style.animation = `fade-in 0.5s ease forwards`;
              //speed of each letter fading in
              letter.style.animationDelay = `${index * 0.08}s`;
            }
          });
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Initial check on component mount

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <Tag ref={elementRef} className="transition-text">
      {text.split(/(?<= )|(?= )/).map((letter, index) => (
        <span
          key={index}
          ref={el => letterRefs.current[index] = el}
          className="letter"
        >
          {letter === ' ' ? '\u00A0' : letter}
        </span>
      ))}
    </Tag>
  );
};

export default TransitionText;
