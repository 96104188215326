import React from 'react'
import './PageNotFound.css'
import pnf from '../images/PageNotFound.jpg'

function PageNotFound() {
  return (
    <>
        <div className='bodyContent'>
            <img className='pnf' src={pnf} alt='error'></img>
            <h1>Looks like you’ve stumbled upon an empty basket!</h1>
            <h4>This page doesn't exist</h4>
        </div>
    </>
  )
}

export default PageNotFound