import 'bootstrap/dist/css/bootstrap.min.css';
//import logo from './logo.svg';
import Menu from './components/Menu';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import './index.css'
import Home from './components/pages/Home';
import Homeowners from './components/pages/Homeowners';
import Donations from './components/pages/Donations';
import Volunteer from './components/pages/Volunteer';
import PageNotFound from './components/pages/PageNotFound';
import Footer from './components/Footer'
import FAQ from './components/pages/FAQ';
import LoginPage from './components/pages/LoginPage'
import SignUpPage from './components/pages/SignUpPage'
import Feedback from './components/pages/Feedback'
import About from './components/pages/About'
import PrivacyPolicy from './components/pages/PrivacyPolicy';
import Blogs from './components/pages/Blog'

import FruitsWeLove from './components/pages/Blogs Posts/FruitsWeLove'
import RevitalizeYourGarden from './components/pages/Blogs Posts/RevitalizeYourGarden'
import Superfruits from './components/pages/Blogs Posts/Superfruits'
import EffectivePlantHydration from './components/pages/Blogs Posts/EffectivePlantHydration'
import HelpingOurVolunteers from './components/pages/Blogs Posts/HelpingOurVolunteers'
import GetLeadership from './components/pages/Blogs Posts/GetLeadership'

function App() {
  
  return (
    <>
      <Router>
        <Menu />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path = "/Homeowners" element={<Homeowners />} />
          <Route path='/Donations' element={<Donations />} />
          <Route path='/Volunteer' element={<Volunteer />} />
          <Route path='/PageNotFound' element={<PageNotFound />} />
          <Route path='/FAQ' element={<FAQ />}/>
          <Route path='/LoginPage' element={<LoginPage />} />
          <Route path='/SignUpPage' element={<SignUpPage />} />
          <Route path='/Feedback' element = {<Feedback />} />
          <Route path='/About' element = {<About />} />
          <Route path='/PrivacyPolicy' element = {<PrivacyPolicy />} />
          
          <Route path='/Blogs' element = {<Blogs />} />
          <Route path='/Blogs/FruitsWeLove' element = {<FruitsWeLove />} />
          <Route path='/Blogs/RevitalizeYourGarden' element = {<RevitalizeYourGarden />} />
          <Route path='/Blogs/Superfruits' element = {<Superfruits />} />
          <Route path='/Blogs/EffectivePlantHydration' element = {<EffectivePlantHydration />} />
          <Route path='/Blogs/HelpingOurVolunteers' element = {<HelpingOurVolunteers />} />
          <Route path='/Blogs/GetLeadership' element = {<GetLeadership />} />

        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
