import React, { useEffect, useState } from "react";
import { Link, useLocation } from 'react-router-dom';
import { IoClose, IoMenu } from "react-icons/io5";
import './Menu.css';
import Login from './Login'; 
import 'react-dropdown/style.css';


import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';

function Menu() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1270);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1270);
      if (window.innerWidth >= 1270) {
        setIsMenuOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    setIsMenuOpen(false);
  }, [location]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <>
      {isMobile ? (
        <>
          <div className='mobileMenuBar'>
            <div className='mobile-menu-left-column'>
              <button onClick={toggleMenu} className="menu-toggle-button">
                {isMenuOpen ? <IoClose /> : <IoMenu />}
              </button>
              <Link to="/" className="menu-logo">FruitShare</Link>
            </div>
             <div className='mobile-menu-right-column'>
                <Login />
              </div>
          </div>
          {isMenuOpen && (
            <>
              <nav className="menu-mobile">
                <button onClick={toggleMenu} className="menu-toggle-button-inner">
                  {isMenuOpen ? <IoClose /> : <IoMenu />}
                </button>
                <div className = 'menu-items'>
                <ul className="nav-menu-mobile">
                <li className='menu-item'>
                    <Dropdown className='menu-links' as={ButtonGroup}>
                      <Link to='/Homeowners' className='submenuLink'>Homeowners</Link>
                      <Dropdown.Toggle className='customDropDownToggle' split id="dropdown-split-basic" />

                      <Dropdown.Menu className='dropdownMobile'>
                      <Link to='/Blogs' className='submenuLink'>
                        <Dropdown.Item as = 'span'>
                          Blog
                        </Dropdown.Item>
                      </Link>
                      <Link to='/Feedback' className='submenuLink'>
                        <Dropdown.Item as = 'span'>
                          Feedback
                        </Dropdown.Item>
                      </Link>
                      <Link to='/FAQ' className='submenuLink'>
                        <Dropdown.Item as = 'span'>
                          FAQ
                        </Dropdown.Item>
                      </Link>
                      </Dropdown.Menu>
                    </Dropdown>
                  </li>
                  <li className='menu-item'>
                    <Link to='/Donations' className='menu-links'>
                      My Donations
                    </Link>
                  </li>
                  <li className='menu-item'>
                    <Link to='/Volunteer' className='menu-links'>
                      Volunteer
                    </Link>
                  </li>
                  <li className='menu-item'>
                    <Link to='/About' className='menu-links'>
                      About Us
                    </Link>
                  </li>
                </ul>
                </div>
              </nav>
            </>
          )}
        </>
      ) : (
        <nav className="menu">
          <div className="menu-container">
             <Link to="/" className="menu-logo">
               FruitShare
             </Link>
             <div className='navmenuContainer'>
               <ul className='nav-menu'>
               <li className='menu-item'>
                  <Dropdown className='menu-links' as={ButtonGroup}>
                    <Link to='/Homeowners' className='submenuLink'>Homeowners</Link>
                    <Dropdown.Toggle className='customDropDownToggle' split id="dropdown-split-basic" />

                    <Dropdown.Menu>
                    <Link to='/Blogs' className='submenuLink'>
                      <Dropdown.Item as = 'span'>
                        Blog
                      </Dropdown.Item>
                    </Link>
                    <Link to='/Feedback' className='submenuLink'>
                      <Dropdown.Item as = 'span'>
                        Feedback
                      </Dropdown.Item>
                    </Link>
                    <Link to='/FAQ' className='submenuLink'>
                      <Dropdown.Item as = 'span'>
                        FAQ
                      </Dropdown.Item>
                    </Link>
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
                 <li className='menu-item'>
                   <Link to='/Donations' className='menu-links'>
                     My Donations
                   </Link>
                 </li>
                 <li className='menu-item'>
                   <Link to='/Volunteer' className='menu-links'>
                     Volunteer
                   </Link>
                 </li>
                 <li className='menu-item'>
                  <Link to='/About' className='menu-links'>
                    About Us
                  </Link>
                </li>
                 <li>
                   
                   <Login />
                 </li>
               </ul>
             </div>
           </div>
         </nav>
      )}
    </>
  );
}

export default Menu;
