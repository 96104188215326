import React from 'react';
import './BlogSummary.css';
import { Link } from 'react-router-dom';

const BlogSummary = ({ linkPath, image, title, content }) => {
  return (
    <Link className='containerLink' to={linkPath}>
        <div className='summaryContainer'>
            <div className='summaryImageContainer'>
                <img src={image} alt={title} />
            </div>
            <h2>{title}</h2>
            <p>{content}</p>
        </div>
    </Link>
  );
}

export default BlogSummary;
