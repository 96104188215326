import React, { useState, useEffect, useRef } from 'react';
import './Infographic.css'; // Ensure this path is correct

const Infographic = ({ imagePath, bodyContent }) => {
  const [isVisible, setIsVisible] = useState(false);
  const infographicRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect(); // Stop observing after the element is visible
        }
      },
      { threshold: 0.1 } // Adjust threshold as needed
    );

    const currentRef = infographicRef.current;

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  return (
    <div ref={infographicRef} className={`infographic ${isVisible ? 'slide-in' : ''}`}>
      <img alt="" src={imagePath} />
      {bodyContent}
    </div>
  );
};

export default Infographic;
