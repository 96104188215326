import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import '../../App.css'
import './LoginPage.css'
import Button from '../Button.js'

import { useNavigate } from 'react-router-dom'


function LoginPage() {

  const baseURL = 'https://fruit-share-a392364d7985.herokuapp.com'

  
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  //check for email. If email exists, authenticate
  //If both line up, login and go to homepage. 
  const handleSignin = async (email) => {
    try {
      const response1 = await axios.get(`${baseURL}/api/checkForUser/${email}`);
      const data1 = response1.data;
      if (data1) {
        localStorage.setItem('userEmail', email.toString());
        alert("Login succesful")
        navigate('/')
      } else {
        alert("Account with this email does not exist");
      }
    } catch (error) {
      console.log(error.message);
      alert("There was an error processing your request, please try again later");
    }
  }

  return (
    <>

    <div className = 'parentdiv1'>
      <div className='loginContainer'>
      
        <h1>Log In</h1>
        <h4>Email:</h4>
        <input className='inputfield' id="email" value={email} type="text" onChange={(e) => setEmail(e.target.value)}/>
        <div clasName='loginButtons'>
          <Button className='variant1' onClick={() => handleSignin(email)}>Login</Button>
          <Link to='/SignUpPage'>Don't have an account?</Link>
        </div>
      </div>
      </div>
    </>
  )
}

export default LoginPage