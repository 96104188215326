import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import '../pages/Donations.css';
import Button from '../Button';
import Popup from 'reactjs-popup';

function Donations() {
  const [ownerName, setOwnerName] = useState("");
  const [contactInfo, setContactInfo] = useState("")
  const [address, setAddress] = useState("");
  const [schedDay, setSchedDay] = useState("");
  const [schedTime, setSchedTime] = useState("");
  const [queryEmail, setQueryEmail] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [additionalInfo, setAdditionalInfo] = useState("");

  const baseURL = 'https://fruit-share-a392364d7985.herokuapp.com'
  
  // Add a new PickRequest
  const addRequest = async (name, contactInfo, address, schedDay, schedTime, additionalInfo) => {
    if(contactInfo.includes('@') || /^\d{3}-?\d{3}-?\d{4}$/.test(contactInfo)) {
      if(localStorage.getItem('userEmail') != null) {
        try {
          var email = localStorage.getItem('userEmail');
          const dateObj = new Date();
          await axios.post(`${baseURL}/api/addNewPickRequest`, {
            'userId': email.toString(),
            "homeownerName": name.toString(),
            "postDate": dateObj.toISOString().split('T')[0],
            "postTime": dateObj.toTimeString().split(' ')[0],
            "scheduledDate": schedDay.toString(),
            "scheduledTime": schedTime.toString(),
            "address": address.toString(),
            "isCompleted": false,
            'additionalInfo': additionalInfo.toString()

          });
          alert("Your request has been submitted!");
          window.location.reload()
          
          console.log('request for signed in user sent');
        } catch (error) {
          console.error("Something went wrong:", error);
          alert("Something went wrong: " + error.message);
        }
      }
      else {
        //create request incognito
        try {
          const dateObj = new Date();
          await axios.post(`${baseURL}/api/addNewPickRequest`, {
            'userId': contactInfo.replaceAll("-", "").replaceAll(" ", "").toString(),
            "homeownerName": name.toString(),
            "postDate": dateObj.toISOString().split('T')[0],
            "postTime": dateObj.toTimeString().split(' ')[0],
            "scheduledDate": schedDay.toString(),
            "scheduledTime": schedTime.toString(),
            "address": address.toString(),
            "fruitType": "NA",
            "imageURL": "",
            "isCompleted": false,
            'additionalInfo': additionalInfo.toString()
          });

          alert("Request Submitted");
          window.location.reload()
          
          console.log('request for non-signed in user sent');
        } catch (error) {
          console.error("Something went wrong:", error);
          alert("Something went wrong: " + error.message);
        }
      }
    }
    else {
      //set the content of a string in red color below to "invalid email or phone number"
      alert("Invalid email or phone number")
    }
    
  };

  const closePopup = (close) => {
    // Remove the "request" parameter from the URL
    const url = new URL(window.location);
    url.searchParams.delete("request");
    window.history.replaceState(null, "", url.toString());
    // Close the popup
    close();
    setIsPopupOpen(false);
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.has("request")) {
      setIsPopupOpen(true);
    }
  }, []);

  //
  //Past and ongoing request stuff
  //
  const getUserArchives = async (email) => {
    try {
        //const response = await axios.get(`${baseURL}/api/findUserByEmail/${email}`);
        const h4Past = document.querySelector("h4.pastDonates");
        const h4Current = document.querySelector('h4.currentDonates');
        
        //const userData = response.data;
        //const id = userData.id;
        
        const pastRequests = await axios.get(`${baseURL}/api/getFinishedRequests/${email}`);
        const pastRequestData = pastRequests.data;
        
        const currentRequests = await axios.get(`${baseURL}/api/getUnfinishedRequests/${email}`);
        const currentRequestData = currentRequests.data;
        
        if (pastRequestData && pastRequestData.length > 0) {
            const pastRequestDisplay = pastRequestData.map((obj, index) => `${index + 1}. Date: ${obj.scheduledDate}. Time: ${obj.scheduledTime}`).join('\n');
            h4Past.innerText = pastRequestDisplay;
        } else {
            h4Past.innerText = "You do not have any past donations.";
        }

        if (currentRequestData && currentRequestData.length > 0) {
            const currentRequestDisplay = currentRequestData.map((obj, index) => `${index + 1}. Date: ${obj.scheduledDate}. Time: ${obj.scheduledTime}`).join('\n');
            h4Current.innerText = currentRequestDisplay;
        } else {
            h4Current.innerText = "You do not have any current donations, please submit a new pick request.";
        }
    } catch (error) {
        console.error('Error:', error);
    }
}


  useEffect(() => {
    const email = localStorage.getItem('userEmail');
    const h4Current = document.querySelector("h4.currentDonates");
    const h4Past = document.querySelector('h4.pastDonates');
    if(email == null) {
      //give the user options
      h4Current.innerText = "Enter your email or log in to view your current donations.";
      h4Past.innerText = "Enter your email or log in to view your past donations."
      //check DB by email

    }
    else {
      getUserArchives(email);
    }
    
    
  }, []);

  async function handleQueryEmail(queryEmail) {
    try {
      queryEmail = queryEmail.replaceAll("-", "").replaceAll(" ", "").toString();
      getUserArchives(queryEmail);
    } catch (error) {
      console.error('Error fetching user data:', error);
      alert('Error fetching user data');
    }
  }

  return (
    <>
    <div className={`mainContent ${isPopupOpen ? 'shrink' : ''}`}>
      <Popup open={isPopupOpen} onClose={() => setIsPopupOpen(false)} modal nested>
        {close => (
          <form className={`popupContainer ${isPopupOpen ? 'open' : ''}`}>
            <div className='columnContainer'>
              <center><h2>Sign Up to Have Your Fruits Picked</h2></center>
              <center><Link to='/PrivacyPolicy'>How we use your information</Link></center>
              <br />
              <div className='columnLeft2'>
              <label>
                  Your Name (Optional):
                  <input id="name" value={ownerName} onChange={(e) => setOwnerName(e.target.value)} type="text" />
                </label>
                <label>
                  Email or Phone #:
                  <input id="contact" value={contactInfo} placeholder='example@email.com or xxx-xxx-xxxx' onChange={(e) => setContactInfo(e.target.value)} type="text" />
                </label>
                <label>
                  Street Name:
                  <input id="homeownerAddress" value={address} onChange={(e) => setAddress(e.target.value)} type="address" />
                </label>
              </div>
              <div className='columnRight2'>
                <label>
                  Enter a day for pickup (Optional):
                  {'\n'}
                  <h5 className='busy'>Since this organization is run by high schoolers, we aren't available for fruit pickings on school days, please schedule to 
                    have your fruits picked anytime on a <strong>weekend</strong>
                  </h5>
                  <input id="scheduledDay" value={schedDay} onChange={(e) => setSchedDay(e.target.value)} type="date" />
                </label>
                <label>
                  Enter a time for pickup (Optional):
                  <input id="scheduledTime" value={schedTime} onChange={(e) => setSchedTime(e.target.value)} type="time" />
                </label>
                <label>
                  Special Instructions(Optional):
                  <input id="specialInstructions" value={additionalInfo} onChange={(e) => setAdditionalInfo(e.target.value)} type="text" />
                </label>
              </div>
            </div>
            <div className='buttons'>
              <Button className='variant1' onClick={() => {
                addRequest(ownerName, contactInfo, address, schedDay, schedTime, additionalInfo);
                close();
              }}>
                Submit Request
              </Button>
              <button className='variant2' onClick={() => closePopup(close)}>
                Cancel
              </button>
            </div>
          </form>
        )}
      </Popup>

      <div className='myDonationsBodyContent'>
        <div className='donationsColumnLeft'>
          <label className='queryEmailForm'>
            <h3>Enter your email or phone number to see your request history:</h3>
            <input id="queryEmail" value={queryEmail} placeholder='example@email.com or xxx-xxx-xxxx' onChange={(e) => setQueryEmail(e.target.value)} type="text" />
            <Button className='variant1' onClick={() => handleQueryEmail(queryEmail)}>Submit</Button>
          </label>

        </div>
        <div className='donationsColumnRight'>
          <h2>Current Donation Registrations</h2>
          <h4 className = "currentDonates">Current Donations</h4>
          <br></br>
          <br></br>
          <br></br>

          <h2>Previous Donations</h2>
          <h4 className = 'pastDonates'>Past Donations</h4>
          <br />
          <br />
          <Link to='?request=new'>
            <center><Button className="variant1" onClick={() => setIsPopupOpen(true)}>Register to Have your Fruits Picked!</Button></center>
          </Link>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
        </div>
      </div>
    </div>
    </>
  );
}

export default Donations;
