import React from 'react'
import '../../App.css'
import './Homeowners.css'
import Infographic from '../Infographic'
import { Link } from 'react-router-dom'
import processinfographic from '../images/theprocessinfographic.png'
import fruitTimeline from '../images/fruitTimeline.png'


function Homeowners() {

  

  return (
    <>
      <div className = "header">
          <h2> The Process </h2>
        
          <div className = "section">
              <h4>To begin, please submit a {"\t"}
              <Link to='/Donations?request=new'>
                pick request.
              </Link>
              </h4>
              
            
          </div>
          <div>
            <center>
              <Infographic imagePath={processinfographic}/>
            </center>
          </div>

          <br></br>
          <br></br>
          <br></br>

          <h2>What Can I Donate?</h2>
          <div className = 'section'>
            <h4>Different fruits have varying windows in which they grow. Make sure your fruit is ripe, and ready to pick to ensure that no fruit goes to waste during harvests. 
              If you tree hasn't started to bear fruit yet, use the information below to learn more about fruit growing patterns. </h4>
            <center>
              <Infographic imagePath={fruitTimeline}/>
            </center>
          </div>
        </div>
    </>
  )
}

export default Homeowners