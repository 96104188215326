import React from 'react'
import '../../App.css'
import GoToTop from '../GoToTop'
function Feedback() {
  return (
    <>
        <GoToTop />
        <center>
            <div className = 'feedbackForm'>
            <iframe title = 'FeedbackFormEmbed' src="https://docs.google.com/forms/d/e/1FAIpQLSdTKtc5eG49IWYFgULGnx7XcctmF9q0esUZ5DkxTqSAfVlnyA/viewform?embedded=true" alt='Loading Feedback Form' width="640" height="870" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
            </div>
            </center>
            
    </>
  )
}

export default Feedback