import React from 'react';
import './PrivacyPolicy.css';

function PrivacyPolicy() {
  return (
    <>
      <div className="privacy-policy">
        <h1>Privacy Policy</h1>

        <p>
            <strong>Note from the Creators of FruitShare:</strong><i> We understand that some individuals may feel hesitant about sharing personal information in order to participate in our fruit-picking program. 
            We acknowledge this concern and have minimized the amount of information required to ensure a smooth interaction between homeowners and volunteers. All information requested on this 
            website is strictly necessary for the successful coordination of our services. The information you provide is only accessible to the assigned volunteer. 
            If you prefer not to store your information on our servers, you may submit a pick request without providing an address. In this case, we will only request the address through your 
            preferred mode of contact shortly before the scheduled pick-up date and time. If you have any questions or would like to know more about our organization and credibility, please feel free to email us at contact.fruitshare@gmail.com.</i>
        </p>
        <p><i>Thanks for your understanding, trust, and support</i></p>
        
        <p><strong>Last Updated: 7/30/2024</strong></p>
        
        <p>
          At FruitShare (hereinafter referred to as "the Organization," "we," "us," or "our"), we value the privacy of our users ("you" or "your"). This Privacy Policy outlines the types of personal information we collect, how we use and protect it, and your rights concerning your data.
        </p>
        
        <h2>1. Information We Collect</h2>
        <p>We collect the following personal information from you when you interact with our services:</p>
        <ul>
          <li><strong>Name:</strong> To address you in our communications.</li>
          <li><strong>Address:</strong> To visit your location for fruit collection.</li>
          <li><strong>Email Address and/or Phone Number:</strong> To communicate with you, including sending confirmation messages and logging pick requests.</li>
        </ul>
        
        <h2>2. How We Use Your Information</h2>
        <p>We use the information collected for the following purposes:</p>
        <ul>
          <li><strong>Name:</strong> To personalize our communications with you.</li>
          <li><strong>Address:</strong> To schedule and carry out fruit collection services at your location. This information is not shared with anyone except the volunteer</li>
          <li><strong>Email Address and/or Phone Number:</strong> To send you confirmation messages, updates, and notifications related to your pick requests. 
          Most of these messages are automated.</li>
        </ul>
        
        <h2>3. Data Storage and Security</h2>
        <p>
          We store your personal information in a secure database and take appropriate measures to protect it from unauthorized access, disclosure, alteration, or destruction. We implement industry-standard security practices to ensure the safety and confidentiality of your data. 
          Because sensetive information isn't stored in a users account (address and name cannot be accessed through the website), a password system is not implemented to ensure ease of use
        </p>
        
        <h2>4. Sharing Your Information</h2>
        <p>
          We do not share, sell, or rent your personal information to third parties. However, we may disclose your information if required by law or if we believe such action is necessary to comply with legal obligations or protect the rights, property, or safety of the Organization or others.
        </p>
        
        <h2>5. Your Rights</h2>
        <p>You have the right to:</p>
        <ul>
          <li>Access and review the personal information we have collected about you.</li>
          <li>Request corrections to any inaccurate or incomplete information.</li>
          <li>Request the deletion of your personal information, subject to legal requirements.</li>
        </ul>
        <p>To exercise these rights, please contact us at contact.fruitshare@gmail.com.</p>
        
        <h2>6. Changes to This Privacy Policy</h2>
        <p>
          We may update this Privacy Policy from time to time to reflect changes in our practices or for legal reasons. We will notify you of any significant changes by posting the updated policy on our website and indicating the date of the latest revision.
        </p>
        
        <h2>7. Contact Us</h2>
        <p>
          If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:
        </p>
        <p>
          FruitShare <br />
          Santa Clara Cty. California <br />
          contact.fruitshare@gmail.com
        </p>
      </div>
    </>
  )
}

export default PrivacyPolicy
