import React from 'react'
import '../../App.css'
import './FAQ.css'
import { Link } from 'react-router-dom'
import Question from '../Question'

function FAQ() {
    return (
        <>
        <Question ask="How do I submit a pick request?" 
            answer={
                <>
                    You can submit a pick request {'\t'}
                    <Link to='/Donations?request=new'>here</Link>.
                    {'\t'}
                    You can find more details about pick requests on our {'\t'}
                    <Link to='/Homeowners'>Homeowners page</Link>.
                </>
            } 
        />
    
        <Question ask="How do I volunteer?" 
            answer={
                <>
                    Visit our volunteers page {'\t'}
                    <Link to='/Volunteer'>here</Link>
                    {'\t'} to contact us about volunteering.
                </>
            } 
        />
    
        <Question ask="What fruit can I donate?" 
            answer={
                <>
                    Visit our Homeowners page {'\t'}
                    <Link to='/Homeowners'>here</Link>
                    {'\t'} to check the calendar on fruits we pick.
                </>
            } 
        />
    
        <Question ask="How long does the picking take?" 
            answer="The picking process can take anywhere from 30 minutes to up to an hour and a half, depending on the number of volunteers and fruits."
        />
    
        <Question ask="When will the volunteers arrive?" 
            answer="You choose the date and time when the fruits will be picked when you submit a pick request, and the volunteers will show up at the scheduled time."
        />
    
        <Question ask="Does this cost money?" 
            answer="No! The service is completely free of charge."
        />
    
        <Question ask="How do I benefit?" 
            answer="You get your fruits picked for free, and you help the less privileged in your community!"
        />
    
        <Question ask="Which regions do you serve?" 
            answer="We currently only serve the San Francisco Bay Area, but hope to expand in the future."
        />
    </>
    

    )
  }
  
  export default FAQ