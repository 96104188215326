import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import blankPfp from '../components/images/blank-pfp.png';
import Dropdown from 'react-bootstrap/Dropdown';
import Image from 'react-bootstrap/Image';
import './Login.css';

import { useNavigate } from 'react-router-dom';

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <button
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    style={{ border: 'none', background: 'none', padding: 0 }}
  >
    {children}
  </button>
));

function Login() {
  const navigate = useNavigate();
  const [user, setUser] = useState({
    name: '',
  });

  const checkForUser = () => {
    const storedEmail = localStorage.getItem('userEmail');
    if (storedEmail) {
      setUser({ name: storedEmail });
    } else {
      setUser({ name: '' });
    }
  };

  useEffect(() => {
    // Initial check for user
    checkForUser();
    // Event listener for storage changes
    const intervalId = setInterval(() => {
      checkForUser();
    }, 1000);

    // Cleanup interval on component unmount
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const handleLogout = () => {
    setUser({
      name: '',
    });
    localStorage.removeItem('userEmail');
    navigate('/');
  };

  const openPopup=()=>{
      window.location.reload();
  }

  const renderDropdownMenu = () => {
    if (user.name) {
      return (
        <Dropdown>
          <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
            <Image className="menu-links" src={blankPfp} alt="PFP" />
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item>
              <Link className="dropdownLinks" to="/Donations">My Donations</Link>
            </Dropdown.Item>
            <Dropdown.Item onClick={openPopup}>
              <Link className="dropdownLinks" to="/Donations?request=new">New Donation</Link>
              
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item onClick={handleLogout}>
              Logout
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      );
    } else {
      return (
        <div className="loginButtons">
          <Link to="/LoginPage">
            <button className="loginTitle" type="button">
              Account
            </button>
          </Link>
        <div className = 'donateTitle' onClick={openPopup}>
          <Link to="/Donations?request=new">
            <button className="signupTitle" type="button">
              Donate
            </button>
          </Link>
        </div>
        </div>
      );
    }
  };

  return (
    <div className="loginStuff">
      {renderDropdownMenu()}
    </div>
  );
}

export default Login;
