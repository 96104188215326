import React from 'react'
import './blogPosts.css'
import chokeberry from './postImages/chokeberry.jpg'
import GoToTop from '../../GoToTop'

function Superfruits() {
  return (
    <>
    <GoToTop />
        <center><div className='singularBlogPostContainer'>
        <img className='blogImage' src={chokeberry} alt = 'chokeberry'/>
    <h2 className='blogTitle'>Discover the Hidden Power of These 3 Superfruits</h2>
    <h5 className='dateAuthor'>Aditya Jha | July 14th, 2024</h5>
    <h4 className='content'>
        While many fruits are celebrated for their health benefits, some lesser-known varieties pack an impressive nutritional punch. These "superfruits" are rich in vitamins, antioxidants, and other nutrients that can boost your health in surprising ways. Here are three superfruits that deserve more recognition and a place in your diet.<br /><br />
        Also known as chokeberries, aronia berries are a small, dark purple fruit that might not be as popular as blueberries or strawberries, but they are nutritional powerhouses. These berries are rich in antioxidants, particularly polyphenols, which help combat oxidative stress and inflammation in the body. They are also high in vitamin C, vitamin K, and fiber. Aronia berries have been linked to improved heart health, reduced inflammation, and even potential cancer-fighting properties. You can enjoy them fresh, dried, or as juice, and they make a great addition to smoothies, yogurt, and baked goods.<br /><br />
        Lucuma, often called the "gold of the Incas," is a sweet, starchy fruit native to Peru. It has a rich, maple-like flavor and is typically found in powdered form outside of its native region. Lucuma is packed with antioxidants, beta-carotene, and essential minerals like iron and potassium. It's also a good source of fiber and a natural sweetener, making it a healthier alternative to sugar. Lucuma has been used traditionally to support skin health, boost the immune system, and provide sustained energy. Its unique flavor makes it a delicious addition to smoothies, desserts, and even coffee.<br /><br />
        Camu Camu is a small, sour fruit native to the Amazon rainforest, known for its extraordinarily high vitamin C content—up to 60 times more than an orange! This potent antioxidant helps boost the immune system, supports collagen production, and fights inflammation. In addition to vitamin C, camu camu contains amino acids, flavonoids, and other antioxidants that can aid in reducing oxidative stress. It's commonly found in powder form, which can be easily added to smoothies, juices, and even water for a nutritious boost.<br /><br />
        Incorporating these superfruits into your diet can provide a range of health benefits. Despite being less well-known, aronia berries, lucuma, and camu camu offer unique flavors and potent nutrients that can enhance your overall well-being. Don't miss out on these hidden gems—add them to your pantry and enjoy their surprising benefits!
    </h4>
</div></center>
    </>
  )
}

export default Superfruits