import React from 'react'
import './blogPosts.css'
import apple from './postImages/apple.jpg'
import GoToTop from '../../GoToTop'

function FruitsWeLove() {
  return (
    <>
    <GoToTop />
        <center><div className='singularBlogPostContainer'>
        <img alt = "" className='blogImage' src={apple} />
        <h2 className='blogTitle'>The Bounty of Backyard Harvests: Fruits We Love and How You Can Contribute</h2>
        <h5 className='dateAuthor'>Arav Bansal | July 6th, 2024</h5>
        <h4 className='content'>
            At Fruit Share, we're passionate about collecting surplus fruits from generous homeowners to share with those in need. The fruits we gather are diverse, reflecting the rich variety of backyard gardens. Some of our favorites include apples, oranges, lemons, pears, and peaches. Each fruit not only adds delicious flavors to meals but also provides essential nutrients to those who might not otherwise have access to fresh produce.<br /><br />
            Apples, for example, are a versatile fruit that can be used in everything from pies to salads. They're rich in fiber and vitamin C, making them a nutritious choice. Oranges, another staple in our collections, are packed with vitamin C and are perfect for juicing or snacking. Lemons, with their vibrant flavor, are not only great for cooking but also have a long shelf life, making them an ideal donation item.<br /><br />
            Pears and peaches, while more delicate, are always a delight to receive. They offer unique flavors and textures that are cherished by many. However, because these fruits can bruise easily, it's important to handle them with care when harvesting and transporting.<br /><br />
            If you have fruit trees at home and are interested in contributing to our cause, there are a few steps you can take to ensure your donations are of the best quality. First, pick your fruits at the right time. Fruits like apples and pears should be picked when they are firm but mature. Oranges and lemons can be picked when they have fully colored. Peaches should be picked when they are slightly soft to the touch.<br /><br />
            Next, handle your fruits gently to avoid bruising, and store them in a cool place until you can donate them. It's also helpful to separate ripe fruits from those that need more time to ripen, as this can prevent over-ripening and spoilage.<br /><br />
            By sharing your surplus fruits, you're not only preventing food waste but also helping to provide fresh, nutritious food to those in need. Your backyard harvest can make a big difference in someone's life. Join us in our mission to reduce food waste and build a stronger, healthier community through the simple act of sharing your fruit harvest.
        </h4>
        </div>
        </center>
    </>
  )
}

export default FruitsWeLove