import React from 'react'
import './Volunteer.css'

function Volunteer() {
  return (
    <>
    <center>
        <div className="contentContainer">
          <h2>If you are interested in volunteering for our organization, contact us at:</h2>
          <h4>contact.fruitshare@gmail.com</h4>
        </div>
        </center>
    </>
  )
}

export default Volunteer